
import ErrorPopup from "@/components/da-signer/ErrorPopup.vue";
import AreYouSurePopup from "@/components/da-signer/AreYouSurePopup.vue";
import SignatureExampleHint from "@/components/da-signer/SignatureExampleHint.vue";

export default {
  name: `AeqDASigner`,
  components: {
    ErrorPopup,
    AreYouSurePopup,
    SignatureExampleHint
  },
  props: {
    label: {
      type: String,
      default: ``
    },
    daCode: {
      type: String,
      default: ``
    },
    value: {
      type: Object,
      default: undefined
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      accept: false,
      acceptFinal: false,
      error: null,
      pdfUrl: null,
      step: 1,
      showModal: false,
      showAreYouSure: false,
      loading: false,
      filteredProps: {},
      signature: null,
      name: null,
      city: null,
      date: null,
      innerValue: this.initValue(this.value),
      buffer: null,
      errorPopup: false,
      signatureExamples: [
        {
          imageUrl: `/images/declaration_of_authorisation/good_signature.jpg`,
          isGood: true,
          criteria: {
            photo: true,
            white_background: true,
            signature_centered: true,
            distance: true
          }
        },
        {
          imageUrl: `/images/declaration_of_authorisation/bad_signature_1.jpg`,
          isGood: false,
          criteria: {
            photo: false,
            white_background: true,
            signature_centered: true,
            distance: true
          }
        },
        {
          imageUrl: `/images/declaration_of_authorisation/bad_signature_2.jpg`,
          isGood: false,
          criteria: {
            photo: true,
            white_background: false,
            signature_centered: true,
            distance: true
          }
        },
        {
          imageUrl: `/images/declaration_of_authorisation/bad_signature_3.jpg`,
          isGood: false,
          criteria: {
            photo: true,
            white_background: true,
            signature_centered: false,
            distance: true
          }
        },
        {
          imageUrl: `/images/declaration_of_authorisation/bad_signature_4.jpg`,
          isGood: false,
          criteria: {
            photo: true,
            white_background: true,
            signature_centered: true,
            distance: false
          }
        }
      ]
    }
  },
  computed: {
    da() {
      return this.$store.getters[`daSigner/declarations`] || []
    },
    isDisabled() {
      return this.filteredProps?.disabled || false
    },
    requirements() {
      return { extensions: this.$config.public.images_extensions }
    },
    traductedErrors() {
      return this.$helpers.wrapInArray(this.errorMessages).map((e) => this.$t(e))
    },
    hasErrors() {
      return this.errorMessages?.length > 0
    },
    token() {
      return this.$router.history.current.query.token || this.$store.state.external.token
    }
  },
  mounted() {
    const parentObject = { $props: {} }
    this.filteredProps = Object.assign(parentObject.$props, this.$attrs)
    this.filteredProps.label = this.label
  },
  methods: {
    clearValues() {
      this.signature = null;
      this.name = null;
      this.city = null;
      this.date = new Date().toISOString().substr(0, 10);
      this.accept = false;
      this.acceptFinal = false;
      this.pdfUrl = null;
      this.step = 1;
      this.error = null;
      this.buffer = null;
    },
    initValue(value) {
      if (value) {
        if (!value.new) {
          value.new = [];
        }
        if (!value.saved) {
          value.saved = [];
        }
      } else {
        value = { new: [], saved: [] };
      }
      return JSON.parse(JSON.stringify(value));
    },
    getDocumentList() {
      let newDocs = [];
      if (this.innerValue.new.length !== 0) {
        newDocs = [
          {
            sourceIndex: 0,
            size: this.innerValue.new[0].size,
            fileName: this.innerValue.new[0].name,
            downloadLink: false,
            deleteLink: false,
            deletable: true,
            data: this.innerValue.new[0].content
          }
        ];
      }
      return newDocs.concat(
        this.innerValue.saved.map((f, i) => ({
          sourceIndex: i,
          ...f
        }))
      );
    },
    async openModal() {
      this.clearValues();
      this.showModal = true;
      await this.$store.dispatch(`daSigner/getDeclarations`, { code: this.daCode, lang: this.$i18n.locale });
    },
    deleteAndOpenModal() {
      this.innerValue.saved.forEach((doc) => {
        this.deleteDocument(doc);
      });
      this.innerValue.new = [];
      this.showAreYouSure = false;
      this.openModal();
    },
    areYouSure() {
      if (this.getDocumentList().length > 0) {
        this.showAreYouSure = true;
        return;
      }
      this.openModal();
    },
    closeModal() {
      this.showModal = false;
      this.clearValues();
    },
    refreshComponent(doc = null, emitBlur = true, emitInput = true) {
      console.log(`refreshComponent`, doc, emitInput);
      if (doc) {
        this.innerValue = this.initValue(doc);
        if (emitBlur) {
          this.$emit(`blur`, doc);
        }
      }
      if (emitInput) {
        this.$emit(`input`, this.innerValue);
      }
      this.refreshCounter += 1;
    },
    async deleteDocument(doc) {
      if (doc.deleteLink) {
        const deleteLinkWithoutApi = doc.deleteLink.replace(/frontoffice/, ``);
        await this.$api.documents
          .delete(deleteLinkWithoutApi)
          .then(() => {
            this.innerValue.saved.splice(doc.sourceIndex, 1);
            this.$flashMessage.success({
              title: `${this.$t(`messages.success`)}`,
              message: this.$t(`messages.document_delete`)
            });
          })
          .catch(() => {
            this.$flashMessage.error({
              title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
              message: this.$t(`messages.cant_delete_document`)
            });
          })
          .finally(() => this.refreshComponent());
      } else {
        this.innerValue.new.splice(doc.sourceIndex, 1);
        this.refreshComponent();
      }
    },
    onCloseModal(isOpen) {
      if (!isOpen) {
        this.clearValues();
      }
    },
    async nextStep() {
      if (this.step === 1) {
        const valid = await this.$refs?.acceptObs?.validate();
        if (valid) {
          this.step++;
        }
      } else if (this.step === 2) {
        const valid = await this.$refs?.daSignerErrors?.validate();
        if (valid) {
          this.generateDA();
          this.step++;
        }
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    submit() {
      this.innerValue.new = this.buffer;
      this.$emit(`input`, this.innerValue);
      this.$emit(`change`, this.innerValue);
      this.showModal = false;
    },
    getDocName() {
      return this.$i18n.t(`components.da_signer.doc_name`) + `${this.name}.pdf`;
    },
    serializeDoc(blobContent) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log(blobContent);
          console.log(reader);
          resolve({
            size: blobContent.size,
            name: this.getDocName(),
            deletable: false,
            content_type: blobContent.type,
            content: reader.result
          });
        };
        reader.readAsDataURL(blobContent);
      });
    },
    async generateDA() {
      this.pdfUrl = null;
      const data = {
        name: this.name,
        city: this.city,
        date: this.date,
        signature: this.signature,
        code: this.daCode,
        declarations: []
      };
      let response;
      try {
        response = await this.$store.dispatch(`daSigner/generateDA`, { data, lang: this.$i18n.locale });
      } catch (error) {
        this.error = error;
        this.step--;
        await this.$refs.daSignerErrors.setErrors(error.data);
        this.errorPopup = true;
        return;
      }
      const newBlob = new Blob([response], { type: `application/pdf` });
      this.pdfUrl = URL.createObjectURL(newBlob);
      this.buffer = [await this.serializeDoc(newBlob)];
      this.loading = false;
    }
  }
};
